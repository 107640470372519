import moment from 'moment'
import * as codes from '@/const/codes.ts'

export default function (list, addressEnList) {

  const thisYear = moment().year()
  const lastYear = thisYear - 1
  const theYearBeforLast = lastYear - 1

  // 取得進出口年分
  let portLastOneYear = thisYear
  let portLastTwoYear = lastYear
  let portLastThreeYear = theYearBeforLast

  // 取得ifrss年份
  let incomeLastOneYear = lastYear - 1911 // 農曆年
  let incomeLastTwoYear = theYearBeforLast - 1911 // 農曆年
  if ((moment().month() + 1) < 4) {
    // 3月底後才會全部公司公佈去年度財報資料
    incomeLastOneYear -=1
    incomeLastTwoYear -=1
    // 3月底後才會有今年的第一季度進出資料
    portLastOneYear -= 1
    portLastTwoYear -= 1
    portLastThreeYear -= 1
  }

  const header = [
    '公司名稱',
    '公司英文名稱',
    '統一編號',
    '發行狀態',
    '代表人',
    '公司所在地址',
    '公司所在地址(英文)',
    '電話',
    'email',
    '資本總額(元)',
    '實收資本總額(元)',
    '公司狀況',
    '核准設立日期',
    '營業項目',
    `${portLastOneYear}進口實績`,
    `${portLastTwoYear}進口實績`,
    `${portLastThreeYear}進口實績`,
    `${portLastOneYear}出口實績`,
    `${portLastTwoYear}出口實績`,
    `${portLastThreeYear}出口實績`,
    // '電子郵件',
    // '登記機關',
    '營業狀況',
    `${incomeLastOneYear}營業收入`,
    `${incomeLastTwoYear}營業收入`
  ]

  const data = list.map((d, i) => {
    let portLastOneYearImport = ''
    let portLastTwoYearImport = ''
    let portLastThreeYearImport = ''
    let portLastOneYearExport = ''
    let portLastTwoYearExport = ''
    let portLastThreeYearExport = ''
    if (d.ports) {
      if (d.ports[portLastOneYear]) {
        let importCode = d.ports[portLastOneYear].import
        let exportCode = d.ports[portLastOneYear].export
        if (codes.IMPORT_AND_EXPORT_DATA[importCode]) {
          portLastOneYearImport = codes.IMPORT_AND_EXPORT_DATA[importCode].label
        }
        if (codes.IMPORT_AND_EXPORT_DATA[exportCode]) {
          portLastOneYearExport = codes.IMPORT_AND_EXPORT_DATA[exportCode].label
        }
      }
      if (d.ports[portLastTwoYear]) {
        let importCode = d.ports[portLastTwoYear].import
        let exportCode = d.ports[portLastTwoYear].export
        if (codes.IMPORT_AND_EXPORT_DATA[importCode]) {
          portLastTwoYearImport = codes.IMPORT_AND_EXPORT_DATA[importCode].label
        }
        if (codes.IMPORT_AND_EXPORT_DATA[exportCode]) {
          portLastTwoYearExport = codes.IMPORT_AND_EXPORT_DATA[exportCode].label
        }
      }
      if (d.ports[portLastThreeYear]) {
        let importCode = d.ports[portLastThreeYear].import
        let exportCode = d.ports[portLastThreeYear].export
        if (codes.IMPORT_AND_EXPORT_DATA[importCode]) {
          portLastThreeYearImport = codes.IMPORT_AND_EXPORT_DATA[importCode].label
        }
        if (codes.IMPORT_AND_EXPORT_DATA[exportCode]) {
          portLastThreeYearExport = codes.IMPORT_AND_EXPORT_DATA[exportCode].label
        }
      }
    }
    let etaxMatters = ''
    if (d.etax_matters_tideup && d.etax_matters_tideup.length) {
      etaxMatters = d.etax_matters_tideup
        .map(d => {
          return `${d.title}(${d.code})`
        })
        .join('、')
    }

    let itemIncome = {}
    if (d.publics && d.publics.IFRSs_str) {
      const itemIfrss = JSON.parse(d.publics.IFRSs_str)
      itemIncome = itemIfrss['營業收入'] || {}
    }    

    return [
      d.company_name || '',
      d.company_name_en_MOEA || '',
      d.uniID || '',
      d.public_issue || '',
      d.representative_name || '',
      d.company_address || '',
      addressEnList[i],
      (d.multi && d.multi.phone && d.multi.phone[0] && d.multi.phone[0].value) ? d.multi.phone[0].value : '',
      (d.multi && d.multi.email && d.multi.email[0] && d.multi.email[0].value) ? d.multi.email[0].value : '',
      d.capital_amount < 0 ? 0 : d.capital_amount || '',
      d.paidup_capital_amount < 0 ? 0 : d.paidup_capital_amount || '',
      d.company_state,
      moment(d.date_approved).format('YYYY/MM/DD') || '',
      etaxMatters || '',
      portLastOneYearImport,
      portLastTwoYearImport,
      portLastThreeYearImport,
      portLastOneYearExport,
      portLastTwoYearExport,
      portLastThreeYearExport,
      // (d.multi && d.multi.email && d.multi.email[0] && d.multi.email[0].value) ? d.multi.email[0].value : '',
      // d.registration_department || '',
      d.company_operate || '',
      itemIncome[`${incomeLastOneYear}年度`] || '',
      itemIncome[`${incomeLastTwoYear}年度`] || '',
    ]
  })

  return {
    header,
    data
  }
}